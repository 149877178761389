
export function getSkills (mainOnly = false) {
  return apiFetch('/skills', { params: { only_main: +(typeof mainOnly === 'boolean' && mainOnly) } })
}

export function getSkill (id) {
  return apiFetch(`/skills/${id}`)
}

export function getSkillLevels () {
  return apiFetch('/skill-levels')
}
